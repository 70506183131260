export const EcommerceLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#0B1611" />
      <path
        d="M7 19L7 7.8L9.75 7.8C9.75 7.03 10.0193 6.37083 10.5578 5.8225C11.0964 5.27417 11.7438 5 12.5 5C13.2563 5 13.9036 5.27417 14.4422 5.8225C14.9807 6.37083 15.25 7.03 15.25 7.8L18 7.8L18 19L7 19ZM8.375 17.6L16.625 17.6L16.625 9.2L15.25 9.2L15.25 11.3L13.875 11.3L13.875 9.2L11.125 9.2L11.125 11.3L9.75 11.3L9.75 9.2L8.375 9.2L8.375 17.6ZM11.125 7.8L13.875 7.8C13.875 7.415 13.7405 7.08553 13.4714 6.8116C13.2024 6.53767 12.8786 6.40047 12.5 6.4C12.1214 6.39953 11.7978 6.53673 11.5293 6.8116C11.2607 7.08647 11.1259 7.41593 11.125 7.8Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
};
