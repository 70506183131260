export const FinanceLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#0B1611" />
      <path
        d="M9.07895 11.3L7.71053 11.3L7.71052 16.2L9.07895 16.2L9.07895 11.3ZM13.1842 11.3L11.8158 11.3L11.8158 16.2L13.1842 16.2L13.1842 11.3ZM19 17.6L6 17.6L6 19L19 19L19 17.6ZM17.2895 11.3L15.9211 11.3L15.9211 16.2L17.2895 16.2L17.2895 11.3ZM12.5 6.582L16.0647 8.5L8.93526 8.5L12.5 6.582ZM12.5 5L6 8.5L6 9.9L19 9.9L19 8.5L12.5 5Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
};
