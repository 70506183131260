import React, { ReactElement } from 'react';
import {
  PATH_ABOUT,
  PATH_AGENT,
  PATH_ARTICLES,
  PATH_BI,
  PATH_CASE_STUDIES,
  PATH_CUSTOMER,
  PATH_DEMO,
  PATH_EBOOKS,
  PATH_FAQ,
  PATH_INDUSTRIES,
  PATH_INTEGRATION,
  PRODUCT_DEMO,
} from '@/constants/spa-routes';
import { AgentCoSupportIcon } from '@/assets/icons/agentCoSupportIcon';
import { CustomerCoSupportIcon } from '@/assets/icons/customerCoSupportIcon';
import { BiCoSupportIcon } from '@/assets/icons/biCoSupportIcon';
import { ZendeskLogo } from '@/assets/icons/zendeskLogo';
import { FreshdeskLogo } from '@/assets/icons/freshdeskLogo';
import { SlackLogo } from '@/assets/icons/slackLogo';
import { ResourcesCaseStudiesLogo } from '@/assets/icons/resourcesCaseStudiesLogo';
import { ResourcesArticleLogo } from '@/assets/icons/resourcesArticleLogo';
import { ResourcesFAQLogo } from '@/assets/icons/resourcesFAQLogo';
import { AgentAiAssistant } from '@/assets/icons/agentAiAssistant';
import { SelfSufficientAi } from '@/assets/icons/selfSufficientAi';
import { AiBusinessIntelligence } from '@/assets/icons/aiBusinessIntelligence';
import { EbooksLogo } from '@/assets/icons/ebooksLogo';
import { EcommerceLogo } from '@/assets/icons/ecommerceLogo';
import { SaasLogo } from '@/assets/icons/saasLogo';
import { FinanceLogo } from '@/assets/icons/financeLogo';

export type NavigationItemsType = {
  id: number;
  title: string;
  href: string;
  isNew?: boolean;
  dropdownItems?: {
    id: number;
    icon?: string | ReactElement;
    title: string;
    href: string;
    description?: ReactElement;
    comingSoon?: boolean;
  }[];
}[];

export const navigationItems: NavigationItemsType = [
  {
    id: 0,
    title: 'Products',
    href: '',
    dropdownItems: [
      {
        id: 2,
        title: 'AI Agent',
        href: PATH_CUSTOMER.ROOT,
        icon: <CustomerCoSupportIcon />,
        description: (
          <>
            Autonomous AI for Customer
            <br /> Service
          </>
        ),
        comingSoon: false,
      },
      {
        id: 1,
        title: 'AI Assistant',
        href: PATH_AGENT.ROOT,
        icon: <AgentCoSupportIcon />,
        description: (
          <>
            AI-Powered Assistant for
            <br /> Support Teams
          </>
        ),
        comingSoon: false,
      },
      {
        id: 3,
        title: 'AI Business Intelligence',
        href: PATH_BI.ROOT,
        icon: <BiCoSupportIcon />,
        description: (
          <>
            AI for Actionable Business
            <br /> Insights
          </>
        ),
        comingSoon: false,
      },
    ],
  },
  {
    id: 1,
    title: 'Interactive Demos',
    isNew: true,
    href: '',
    dropdownItems: [
      {
        id: 11,
        title: 'AI Agent',
        href: PATH_DEMO.CUSTOMER_DEMO,
        icon: <SelfSufficientAi />,
        description: (
          <>
            Try Autonomous AI for
            <br />
            Customer Service
          </>
        ),
        comingSoon: false,
      },
      {
        id: 10,
        title: 'AI Assistant',
        href: PRODUCT_DEMO.AGENT,
        icon: <AgentAiAssistant />,
        description: (
          <>
            Try AI-Powered Assistant for
            <br /> Support Teams
          </>
        ),
        comingSoon: false,
      },
      {
        id: 12,
        title: 'AI Business Intelligence',
        href: PRODUCT_DEMO.BI,
        icon: <AiBusinessIntelligence />,
        description: (
          <>
            Try AI for Actionable Business
            <br />
            Insights
          </>
        ),
      },
    ],
  },
  {
    id: 2,
    title: 'Integrations',
    href: '',
    dropdownItems: [
      {
        id: 22,
        title: 'Zendesk',
        icon: <ZendeskLogo />,
        description: (
          <>
            One of the oldest cloud-based
            <br /> customer service platforms
          </>
        ),
        href: PATH_INTEGRATION.INTEGRATION_ZENDESK,
        comingSoon: false,
      },
      {
        id: 23,
        title: 'Freshdesk',
        icon: <FreshdeskLogo />,
        description: (
          <>
            Cloud-based software designed
            <br /> to improve customer support
          </>
        ),
        href: PATH_INTEGRATION.INTEGRATION_FRESHDESK,
        comingSoon: false,
      },
      {
        id: 24,
        title: 'Slack',
        icon: <SlackLogo />,
        description: (
          <>
            Business team communication
            <br />
            platform
          </>
        ),
        href: PATH_INTEGRATION.INTEGRATION_SLACK,
        comingSoon: true,
      },
    ],
  },
  {
    id: 9,
    title: 'Industries',
    href: '',
    dropdownItems: [
      {
        id: 222,
        title: 'Ecommerce',
        icon: <EcommerceLogo />,
        description: (
          <>
            Automate Support & Boost
            <br /> Sales
          </>
        ),
        href: PATH_INDUSTRIES.INDUSTRIES_AI_ECOMMERCE_ASSISTANT,
        comingSoon: false,
      },
      {
        id: 233,
        title: 'SaaS & Technology',
        icon: <SaasLogo />,
        description: (
          <>
            AI for SaaS & Technology:
            <br />
            Automate, Scale & Improve CX
          </>
        ),
        href: PATH_INDUSTRIES.INDUSTRIES_SAAS_TECHNOLOGY,
        comingSoon: true,
      },
      {
        id: 244,
        title: 'Finance & Banking',
        icon: <FinanceLogo />,
        description: (
          <>
            Finance & Banking: <br />
            Deliver Personalized Financial Insights
          </>
        ),
        href: PATH_INDUSTRIES.INDUSTRIES_FINANCE_BANKING,
        comingSoon: true,
      },
    ],
  },
  {
    id: 3,
    title: 'Resources',
    href: '',
    dropdownItems: [
      {
        id: 31,
        title: 'Case Studies',
        icon: <ResourcesCaseStudiesLogo />,
        description: (
          <>
            See CoSupport AI drive efficiency
            <br /> across global businesses
          </>
        ),
        href: PATH_CASE_STUDIES.ROOT,
        comingSoon: false,
      },
      {
        id: 32,
        title: 'Articles',
        icon: <ResourcesArticleLogo />,
        description: (
          <>
            Discover insightful articles on
            <br /> diverse industry topics
          </>
        ),
        href: PATH_ARTICLES.ROOT,
        comingSoon: false,
      },
      {
        id: 33,
        title: 'FAQ',
        icon: <ResourcesFAQLogo />,
        description: (
          <>
            Find answers to your common
            <br />
            questions here
          </>
        ),
        href: PATH_FAQ.ROOT,
        comingSoon: false,
      },
      {
        id: 34,
        title: 'Ebooks',
        icon: <EbooksLogo />,
        description: (
          <>
            AI strategies, tools, and best
            <br />
            practices
          </>
        ),
        href: PATH_EBOOKS.AI_CUSTOMER_STRATEGY,
        comingSoon: false,
      },
    ],
  },
  {
    id: 4,
    title: 'About Us',
    href: PATH_ABOUT.ROOT,
  },
];

export const navigationItemsFooter: NavigationItemsType = [
  {
    ...navigationItems[0],
    dropdownItems: [
      ...(navigationItems[0].dropdownItems ?? []),
      {
        id: 14,
        title: 'Request demo',
        href: PATH_DEMO.SCHEDULE_DEMO,
        comingSoon: false,
      },
    ],
  },
  { ...navigationItems[1] },
  { ...navigationItems[2] },
  { ...navigationItems[3] },
  { ...navigationItems[4] },
  {
    id: 155,
    title: 'Company',
    href: '',
    dropdownItems: [
      {
        id: 4,
        title: 'About Us',
        href: PATH_ABOUT.ROOT,
      },
    ],
  },
];
