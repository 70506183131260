export const SaasLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="25" rx="4" fill="#0B1611" />
      <path
        d="M6 6L19 6L19 19L6 19L6 6ZM7.44444 7.44444L7.44444 11.7778L17.5556 11.7778L17.5556 7.44445L7.44444 7.44444ZM17.5556 13.2222L7.44444 13.2222L7.44444 17.5556L17.5556 17.5556L17.5556 13.2222ZM8.88889 8.88889L10.3333 8.88889L10.3333 10.3333L8.88889 10.3333L8.88889 8.88889ZM10.3333 14.6667L8.88889 14.6667L8.88889 16.1111L10.3333 16.1111L10.3333 14.6667Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
};
