export const EbooksLogo = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="25" height="25" rx="4" fill="#0B1611" />
      <path
        d="M11.2 13.8L13.8 13.8L13.8 12.5L11.2 12.5L11.2 13.8ZM11.2 11.85L16.4 11.85L16.4 10.55L11.2 10.55L11.2 11.85ZM11.2 9.9L16.4 9.9L16.4 8.6L11.2 8.6L11.2 9.9ZM8.6 16.4L8.6 6L19 6L19 16.4L8.6 16.4ZM9.9 15.1L17.7 15.1L17.7 7.3L9.9 7.3L9.9 15.1ZM6 19L6 8.6L7.3 8.6L7.3 17.7L16.4 17.7L16.4 19L6 19Z"
        fill="white"
        fillOpacity="0.4"
      />
    </svg>
  );
};
